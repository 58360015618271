import React, {useEffect, useState } from "react";
import SecondBlock from "../Component/SecondBlock"
import ThirdBlock from "../Component/ThirdBlock"
import FourthBlock from "../Component/FourthBlock"
import FivethBlock from "../Component/FivethBlock"
import RSS from "../Component/RSS"
import NavBar from "../Component/NavBar"
import NavBarMobile from "../Component/NavBarMobile"
import FormToReply from "../Component/FormToReplay";
import Vacansies from "../Vacansies";
import ButtonTop from "../Ui/ButtonTop";


const IndexPage =()=>{

  const[place, setPlace] = useState("")
  const options = Vacansies.vacansies
  const param = null
    const[optionNum, setOptionNum] = useState(0)
    const[selectedOption, setSelectedOption]=useState(options[optionNum])
    const [isActive, setActive] = useState("false");
    const [dimensions, setDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const handleResize = () => {
      setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
      });
    }
    useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    }, []);
    return(
      <>
     {
        (window.innerWidth <=481) ? <NavBarMobile isActive={isActive} setActive={setActive}/> : <NavBar isActive={isActive} setActive={setActive}/>
      }
      <ButtonTop/>
      <SecondBlock/>
      <ThirdBlock/>
      <FourthBlock setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
      <FivethBlock/>
      <FormToReply setPlace={setPlace} place={place} optionNum={optionNum} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
      <RSS/>

    </>
    )
}

export default IndexPage