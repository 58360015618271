import React from "react";
import arrow from "./css/images/ArrowWhite.png"
import MoreButton from "../Ui/MoreButton";
import { Link } from "react-router-dom";



const VacLi =({selectedOption, setSelectedOption, setOptionNum, setPlace})=>{



    return(
        <div className="VacansWrapper">
        <div className="Vacans">
            <div className="VacansName">Слесаря, Кладовщики, Электрики</div>
            <div className="VacansPrice"> 130 860 ₽</div>
            <div className="VacansName Gradi"></div>
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption} vacName={"Слесаря, Кладовщики, Электрики"} ItName={2}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
            </div>
        </div>
        <div className="Vacans">
            <div className="VacansName">Охранник КПП, Снабженец, Заправщик автомобилей</div>
            <div className="VacansPrice"> 134 490 ₽</div>
            <div className="VacansName Gradi"></div>
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} vacName={"Охранник КПП, Снабженец, Заправщик автомобилей"} setSelectedOption={setSelectedOption} ItName={3}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
        </div>
        <div className="Vacans">
            <div className="VacansName">Водянщики</div>
            <div className="VacansPrice"> 138 120 ₽</div>
            <div className="VacansName Gradi"></div>
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} vacName={"Водянщики"} setSelectedOption={setSelectedOption} ItName={4}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
        </div>
        <div className="Vacans">
            <div className="VacansName">Машинист крана автомобильного 25тн</div>
            <div className="VacansPrice">214 350 ₽</div>
            <div className="VacansName Gradi"></div>
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} vacName={"Машинист крана автомобильного 25тн"} setSelectedOption={setSelectedOption} ItName={5}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
        </div>
        <div className="Vacans">
            <div className="VacansName">Машинист крана автомобильного 40 тн, 50тн, 60тн</div>
            <div className="VacansPrice"> 239 760 ₽</div>
            <div className="VacansName Gradi"></div>
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} vacName={"Машинист крана автомобильного 40 тн, 50тн, 60тн"} setSelectedOption={setSelectedOption} ItName={6}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
        </div>
        <div className="Vacans">
            <div className="VacansName">Машинист экскаватора Hitachi ZX330</div>
            <div className="VacansPrice">221 610 ₽</div>
            <div className="VacansName Gradi"></div>
            <div className="MoreInfo" >
                            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} vacName={"Машинист экскаватора Hitachi ZX330"} setSelectedOption={setSelectedOption} ItName={7}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
        </div>
    </div>
    )
}

export default VacLi