import React from "react";
import arrow from "./css/images/ArrowWhite.svg"
import MoreButton from "../Ui/MoreButton";
import Vacansies from "../Vacansies";
import { Link } from "react-router-dom";


const IterVacMob =({selectedOption, setSelectedOption, setOptionNum, setPlace})=>{

    const vac = Vacansies.vacansies

    return(
        <div className="VacansWrapp">
            {   
                vac.slice(1).map((items, index) => {
                return(
                        
                    <div key={index + items.title} className="Vacans">
                        <div className="VacansName">{items.value}
                        <div className="MoreInfo" >
                        <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption} vacName={String(items.value)} ItName={Number(index+1)}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
                        </div>
                        <div className="VacansPrice">{items.price}</div>
                        <div className="VacansName Gradi"></div>
                        
                    </div>    
                
                )        
            })
            }
        </div>
    )
}

export default IterVacMob