import React from "react";
import ImgData from "../ImgData";
import TechCard from "./TechCards";
import Slider from "./Slider";



const TechElemSW =()=>{

    const items = ImgData.events

    return(
        <div className="TechWrapperSliderWrapper">
            <Slider
            Component={TechCard}
            data={items}
            />

        </div>
    )
}

export default TechElemSW