import React from "react";
import './css/FirstBlock.css'


const FirstBlock =()=> {


    return(
        <div className="FirstBlock" >
            <div className="FirstWrapper">
                <div className="HeaderWrapper">
                    <div className="TextHeader">ЛОГИСТИЧЕСКИЕ РЕШЕНИЯ</div>
                    <div className="PointsWrapper">
                    <div className="LogoPoint">
                            <img src="./Images/HeaderPoint.webp" alt=""></img>
                        </div>
                        <div className="TextPoint">
                            <img src="./Images/BodyPoint.webp" alt=""></img>
                        </div>
                    </div>
                </div>
                <div className="BodyWrapper">
                    <img src="./Images/FirstBodyImg.webp" alt=""></img>
                </div>
                <div className="SIBlock"  id="Us">
                    <div className="SIBBlockOne">

                        <div className="TrackImg"></div>
                        <div className="AboutUsWrapper">
                        <div className="AboutBody">
                                <p><span style={{fontFamily: "Inter-Bold"}}>СИБАВТОКОМ</span> – компания была основана в 2013 году и уже успела зарекомендовать себя как ответственная организация.</p>
                                    <br></br>
                                <p>Многолетний опыт работы и квалификация персонала, отличное состояние автомобильно-технического парка позволяет нам обеспечивать надежность, безопасность, оперативность всех видов перевозок, высокое качество предоставляемых услуг.</p>
                        </div>
                        <div className="PointsPlusesWrapper">
                            <div className="PointPlus">
                                <div className="BigNum">10</div>
                                <div className="TextPluses">Лет на рынке грузоперевозок</div>
                            </div>
                            
                            <div className="PointPlus">
                                <div className="BigNum">400+</div>
                                <div className="TextPluses">Единиц техники в автопарке</div>
                            </div>
                            
                        </div>
                        
                    </div>

                    </div>
                    <div className="SIBBlockTwo">
                        <div className="Location"><div className="GeoLogo"></div><div className="GeoText">Офис компании г. Омск, улица 19 Амурская 69А.</div></div>
                        <div className="Location"><div className="GeoLogo"></div><div className="GeoText">Cобственная площадка на территории Нового Уренгоя</div></div>
                    </div>    
                </div>
            </div>
        </div>
    )
}

export default FirstBlock