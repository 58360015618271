import React from "react"
import { HashLink } from "react-router-hash-link";



const ButtonMob =({ clas, value, anchor, isActive, setActive})=>{


    const handleToggle = () => {
        setActive(!isActive);
        isActive ? document.body.style.overflow = "hidden" : document.body.style.overflow = "scroll"
    };



    return(
        <div>
           <HashLink className={clas} to={anchor} onClick={handleToggle}>{value}</HashLink>
        </div>
    )
    
}

export default ButtonMob