export default {
    shots:[
      {
        imgGay:'/Images/schulmberger.webp',
        imgCal:'/Images/schlumberger.webp',
        w: '399px',
      },
      {
        imgGay:'/Images/RedYar.webp',
        imgCal:'/Images/redYarc.webp',
        w:'246px',
      },
      {
        imgGay:'/Images/GazBig.webp',
        imgCal:'/Images/GazBigC.webp',
        w:'551px',
      },
      {
        imgGay:'/Images/Gaz1.webp',
        imgCal:'/Images/Gaz1c.webp',
        w:'183px',
      },
      {
        imgGay:'/Images/RusNef.webp',
        imgCal:'/Images/RusNefc.webp',
        w:'150px',
      },
      {
        imgGay:'/Images/cck.webp',
        imgCal:'/Images/cckc.webp',
        w:'219px',
      },
      {
        imgGay:'/Images/shiiiit.webp',
        imgCal:'/Images/shiiiitc.webp',
        w:'90px',
      },
      {
        imgGay:'/Images/RusVel.webp',
        imgCal:'/Images/RusVeldCol.webp',
        w:'379px',
      },
      {
        imgGay:'/Images/Gis.webp',
        imgCal:'/Images/GisCol.webp',
        w:'492px',
      },
      {
        imgGay:'/Images/RusGaz.webp',
        imgCal:'/Images/RusGazCol.webp',
        w:'408px',
      },

    ]
}