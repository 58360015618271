import React from "react";
import './css/NavBarMobile.css'
import FirstBlockMobile from "./FirstBlockMobile";
import ButtonMob from "../Ui/ButtonMob";


const NavBarMobile =({setActive, isActive})=>{

    
    const BurgerToggle = () => {
        setActive(!isActive);
        isActive ? document.body.style.overflow = "hidden" : document.body.style.overflow = "scroll"
    };

    return(
        <>
        <div className={`NavBar ${!isActive ? "open-menu": " "}`} id="nav">
       
        <div className={`NavBarConteiner ${!isActive ? "open-menu": " "}`}>
            <a href="/" aria-label="Link to main page">
            <div className="Logo"></div>
            </a>
            <div className={`menu-burger__header ${!isActive ? "open-menu ": " "}`} onClick={BurgerToggle}>
                <span></span>
            </div>
        </div>
        <div className={`NavWrapper ${!isActive ? "open-menu": ""}`} >
                <div className={`NavPanel ${!isActive ? "open-menu": ""}`}>
                <ButtonMob  clas={"Points"} value={"О нас"} anchor="#Us" isActive={isActive} setActive={setActive}/>
                <ButtonMob  clas={"Points"} value={"Для заказчиков"} anchor="#Byer" isActive={isActive} setActive={setActive}/>
                <ButtonMob  clas={"Points"} value={"Техника"} anchor="#Techs" isActive={isActive} setActive={setActive}/>
                <ButtonMob  clas={"Points"} value={"Для устройства на работу"} anchor="#Employ" isActive={isActive} setActive={setActive}/>
                </div>
                </div>
                </div>
        <FirstBlockMobile isActive={isActive} setActive={setActive}/>
        
      </>
    )
}

export default NavBarMobile