import React from "react";
import {Routes, Route, Navigate} from 'react-router-dom'
import { INDEX_ROUTE } from "../utils/consts";
import { publicroutes } from "../routes";



const AppRouter = () => {

    return(
        
        <Routes> 
             {publicroutes.map(({path, component}) =>
                <Route  key={path} path={path} element={component()}/>
            )}
            <Route  path="*" element ={<Navigate replace to={INDEX_ROUTE}/>} />
        </Routes>
    )
} 

export default AppRouter 