// eslint-disable-next-line import/no-anonymous-default-export
export default {
    events:[
        {
            image: 'url("/Images/takerForSmallSlider.png")',
            images: ['/Images/takerForSmallSlider.png', '/Images/SliderImg/gruz/1.png', '/Images/SliderImg/gruz/2.png', '/Images/SliderImg/gruz/3.png', '/Images/SliderImg/gruz/4.png'],
            name:  'Грузоперевозящая техника',
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ",
            description: ["Тягач седельный  «КАМАЗ» - 136 ед", "Тягач седельный «SCANIA» - 19 ед", "Тягач седельный «MAN» - 5 ед", "Тягач седельный «FAW» - 5 ед", "ПП - 67 ед", "ППЦ - 57 ед"],

        },
        {
            image: 'url("/Images/TakerSS.webp")',
            images: ['/Images/Bolotohod.png', "/Images/SliderImg/boloto/1.png", "/Images/SliderImg/boloto/2.png", "/Images/SliderImg/boloto/3.png"],
            name:  'Болотоходы',
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ",
            description: ["Егерь 32842 – 2 ед", "Егерь 3897 – 7 ед"],

        },
        {
            image: 'url("/Images/OrangeKamaz.png")',
            images: ["/Images/OrangeKamaz.png", '/Images/SliderImg/spec/1.png', '/Images/SliderImg/spec/2.png', '/Images/SliderImg/spec/3.png', '/Images/SliderImg/spec/4.png'],
            name:  'Специализированная техника',
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ",
            description: ["ППУ - 5 ед", "ЦА и Компрессорная установка - 8 ед", "Автоцистерны для технической и питьевой воды - 13 ед", "Вахтовки - 20 ед"],
        },
        {
            image: 'url("/Images/ToyotaSmall.png")',
            images: ["Images/ToyotaSmall.png", '/Images/SliderImg/oper/1.png', '/Images/SliderImg/oper/2.png', '/Images/SliderImg/oper/3.png', '/Images/SliderImg/oper/4.png'],
            name:  'Оперативный транспорт',
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ",
            description: ["Toyota Land Cruiser - 5 ед",  "Renault Duster - 7 ед", "Chevrolet Niva  - 3 ед"],
        },
        {
            image: 'url("/Images/GroundDestSmallSlider.png")',
            images: ["/Images/GroundDestSmallSlider.png", '/Images/SliderImg/zemlia/1.png', '/Images/SliderImg/zemlia/2.png', '/Images/SliderImg/zemlia/3.png', '/Images/SliderImg/zemlia/4.png'],
            name:  'Землеройная техника',
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ",
            description: ["Бульдозеры - 16 ед", "Погрузчики - 19 ед", "Самосвалы - 4 ед"],
        },
        {
            image: 'url("/Images/AutoSmallSlider.png")',
            images: ["/Images/AutoSmallSlider.png", '/Images/SliderImg/kran/1.png', '/Images/SliderImg/kran/2.png', '/Images/SliderImg/kran/3.png', '/Images/SliderImg/kran/4.png'],
            name:  'АвтоКраны',
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ",
            description: ["25т - 43 ед", "40т - 5 ед", "50т - 13 ед", "КМУ - 5 ед"],
        }
    ]
}