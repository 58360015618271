import React from "react";
import './css/Slider.css'

export default function Slider({Component, data}){

    
    return(
        <div className="Slider">
            <div className="SliderWrapper">
                {
                    data.map((item, index)=>(
                        <div className="Slide" key={item.name + index}>
                            <Component {...item}/>
                        </div>
                    ))
                }
            </div>
         </div>
    )
}