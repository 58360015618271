import React, {useEffect, useState } from "react";
import VacansList from "../Component/VacansLists";
import FivethBlock from "../Component/FivethBlock";
import FormToReply from "../Component/FormToReplay";
import NavBarMobileVac from "../Component/NavBarMobileVac";
import NavBarVac from "../Component/NavBarVac";
import Vacansies from "../Vacansies";
import ButtonTop from "../Ui/ButtonTop";


const Vacans =()=>{
    const[place, setPlace] = useState("")
    const options = Vacansies.vacansies
    const param = 2
    const[optionNum, setOptionNum] = useState(0)
    const[selectedOption, setSelectedOption] = useState(options[optionNum])
    const [isActive, setActive] = useState("false");
    const [dimensions, setDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const handleResize = () => {
      setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
      });
    }
    useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    }, []);
    return(
        <>
             {
         (window.innerWidth <=481) ? <NavBarMobileVac isActive={isActive} setActive={setActive}/> : <NavBarVac isActive={isActive} setActive={setActive}/>
        }
        <ButtonTop/>
        <VacansList setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
        <FivethBlock/>
        <FormToReply setPlace={setPlace} place={place} optionNum={optionNum} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
        </>
    )
}

export default Vacans