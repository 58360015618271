import React, { useState} from "react";
import ym from 'react-yandex-metrika';
import Vacansies from "../Vacansies";
import "./css/Form.css"
import arrow from "./css/images/ArrowWhite.svg"
import pepe from "./css/images/paperClip.svg"
import InputMask from 'react-input-mask';
import axios from 'axios';
import Select from 'react-select';







const FormToReply =({selectedOption, setSelectedOption, setPlace, place})=>{


const colourStyles = {
    control: (styles, {isSelected, isFocused}) =>{
        return{
            ...styles, 
            '&:hover': {
                border: '2px solid #486ADA'
             },

             'focus':{
                border: '2px solid #486ADA',
                boxshadow: "none"
             },
             alignContent:"center",
            cursor: "pointer",
            zIndex: '82',
            padding:'10px',
            backgroundColor: isFocused ? "rgba(72, 106, 218, 0.15)" : 'white',
            borderRadius: "20px", 
            border: isFocused ? "2px solid #486ADA" : '2px solid #486ADA',
            boxShadow: '0',
            width: "744px", 
            heigth:"55px",
            "@media (max-width: 5500px)":{
                width: "744px", 
                height:"55px",
                borderRadius: "20px", 
                border:  '2px solid #486ADA',
                fontSize: '21px',
            },
            "@media (max-width: 1920px)":{
                width: "38.5vw",
                height:"2.9vw",
                borderRadius: "1vw",
                fontSize: '1.1vw',
            },
            "@media (max-width: 1000px)":{
                width: "96vw",
                height:"5.5vw",
                borderRadius: "2vw",
                fontSize: '2.1vw',
            },
            "@media (max-width: 768px)":{
                width: "94.8vw",
                height:"7.2vw",
                borderRadius: "2.6vw",
                fontSize: '2.7vw',
            },
            "@media (max-width: 480px)":{
                width: "95vw",
                height:"11.5vw",
                borderRadius: "4.2vw",
                fontSize: '4.4vw',
            },
            "@media (max-width: 320px)":{
                height:"15vw",
            },



        }
    },
    option: (styles, {isSelected, isFocused}) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "rgba(72, 106, 218, 0.15)" : 'white',
          color: '#373737',
          cursor: "pointer",
          borderRadius: "7px",
        };
      },
      indicatorSeparator: styles =>({...styles, width:"0"}),
      menu: (styles, {isSelected, isFocused}) => {
        return {
        ...styles, 
        overflow:"scroll", 
        height:"365px", 
        padding:"30px",
        boxShadow:"none", 
        top: "20px",
        background: "#FFF", 
        zIndex:"80", 
        borderRadius: "0 0 20px 20px", 
        border: '2px solid #486ADA', 
        borderTop:"none",
        "@media (max-width: 1000px)":{
            padding:"30px  0  0  15px ",
        }, 
        "@media (max-width: 768px)":{
            padding:"30px  0  0  15px ",
        },
        "@media (max-width: 480px)":{
            padding:"30px  0  0  15px ",
        },
        }},
      indicatorsContainer: (provided, state) => ({
        ...provided,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        transition: 'all .2s ease',
        marginTop: "3px",
        borderRadius:"10px", 
        backgroundPosition: "center",
        backgroundRepeat:"no-repeat" ,
        backgroundColor: '#363638', 
        backgroundImage:"url('./Images/ArrowDown.svg')", 
        width:"35px", 
        height:"35px",
        backgroundSize: "50%",
       
        '&:hover': {
            backgroundPosition: "50% 70%",
         },
         '&:focus': {
            transform: "rotate(180deg)",
         },
         "@media (max-width: 5500px)":{
            width:"35px", 
            height:"35px",
            borderRadius:"10px",
        },
        "@media (max-width: 1920px)":{
            width: "1.8vw",
            height:"1.8vw",
            borderRadius:"0.5vw", 
        },
        "@media (max-width: 1000px)":{
            width: "3.5vw",
            height:"3.5vw",
            borderRadius:"1vw", 
        },
        "@media (max-width: 768px)":{
            width: "4.6vw",
            height:"4.6vw",
            borderRadius:"1.3vw", 
        },
        "@media (max-width: 480px)":{
            width: "7.3vw",
            height:"7.3vw",
            borderRadius:"2.1vw", 
        },
      }),
      menuList: (base) => ({
        ...base,
        marginTop: "5px",
        width:"693px",
        padding: "0px 10px 0 0",
        "@media (max-width: 1920px)":{
            width: "35.1vw",
        },
        "@media (max-width: 1000px)":{
            width: "91.3vw",
        },
        "@media (max-width: 768px)":{
            width: "90.3vw",
        },
        "@media (max-width: 480px)":{
            width: "88.2vw", 
        },
        "@media (max-width: 320px)":{
            width: "85.2vw", 
        },
    
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",

        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
          border: '1px solid #f1f1f1',
          borderRadius:"400vw", 

        },
        "::-webkit-scrollbar-thumb": {
          background: "#486ADA",
          border: '1px solid #486ADA',
          borderRadius:"400vw", 

          
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#486ADA"
        },
      })
} 


const vacanss = Vacansies.vacansies 
const [state, setState] = useState('')
const [fError, setFError] = useState()
const [errModal, setErrModal] = useState(false)
const [phoneVal, setPhoneVal] = useState(0)
 
const [openModal, setOpenModal] = useState(false)
const [sendMod, setSendMod] = useState(false)




const[errorr, setError] = useState(0)


const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "",);
    setPhoneVal(onlyDigits);
  };

const Submit = async event => {
    event.preventDefault()

   


    if(localStorage.getItem("id") === null){
        if(document.querySelector("#file").value !== ""){
        if(phoneVal.length < 11){
            
            setErrModal(true)
            return;
        }else{ 
            if(selectedOption.value === "Выберите подходящую вакансию"){
                setErrModal(true)
                return;
            }else{
            const form = document.querySelector("form");
            const formData = new FormData(form);
    
            formData.set("price", selectedOption.price)
    
    
        await axios({
            method: "POST",
            credentials:"include",
            mode:"cors",
            url: 'https://api.sibautocom.net',
            headers: {
                "Access-Control-Allow-Origin": "https://sibautocom.net", 
                "Content-Type": "multipart/form-data",
            },
            data: formData,
          })
          .then((response) => {
            ym('reachGoal' , 'get-resume');
            localStorage.setItem("id", 1)
            setOpenModal(true)
          })
          .catch(function (error) {
            setErrModal(true)
            setError(error)
       });
            }
            
        }
        }else{
            if(phoneVal.length < 11){
                
                setErrModal(true)
                return;
            }else{ 
                if(selectedOption.value === "Выберите подходящую вакансию"){
                    setErrModal(true)
                    return;
                }else{
                const form = document.querySelector("form");
                const formData = new FormData(form);
        
                formData.set("price", selectedOption.price)
        
        
            await axios({
                method: "POST",
                credentials:"include",
                mode:"cors",
                url: 'https://api.sibautocom.net/justSend',
                headers: {
                    "Access-Control-Allow-Origin": "https://sibautocom.net", 
                    "Content-Type": "application/json",
                },
                data: formData,
              })
              .then((response) => {
                ym('reachGoal' , 'get-resume');
                localStorage.setItem("id", 2)
                setOpenModal(true)
              })
              .catch(function (error) {
                setErrModal(true)
                setError(error)
           });
                }
                
            }
        }
    }else{
        setSendMod(true)
    }

   
    
    

    
}


const SlectHandler = (selectedOption) =>{
    if(selectedOption.value !== "Выберите подходящую вакансию"){
        setSelectedOption(selectedOption)
        setPlace(selectedOption.value)
        document.querySelector("#About").defaultValue = "Добрый день, меня заинтересовала вакансия на должность " + selectedOption.value
    }else{
        setSelectedOption(selectedOption)
    }
    

}




    return(
        
        <div className="FormBlock">
        <form className="Form" action="" method="get" onSubmit={() =>{Submit();}}>
           
            {
                window.innerWidth > 480 ?  <div className="FormHeader">ФОРМА ОБРАТНОЙ СВЯЗИ <a name="form"></a></div> :  <div style={{textAlign:"center"}} className="FormHeader">ФОРМА <p style={{textAlign:"center"}}>ОБРАТНОЙ СВЯЗИ</p> <a name="form"></a></div>
            }
           
            <div className="FormWrapp">
                <div className="FIOWrapp">
                    <div className="FIO">
                        <div className="Fio"> <label className="Lab" >ФИО</label>
                         <input id="name" className="NameIn"  name="name" placeholder=""  type="text"></input></div>
                        <div className="Fio"> <label className="Lab" >Телефон</label>
                        <InputMask onChange={(e)=> checkInput(e)} id="tel"  className="TelIn" minLength={12}  name="tel" mask="+9 999 999 99 99" placeholder=""  type="tel"></InputMask></div>
                    </div>
                    <div className="VacChange">
                    <label className="Lab" >Вакансия</label>
                        <Select 
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        value={selectedOption}
                        styles={colourStyles}
                        name="vacans"
                        options={vacanss}
                        onChange={(selectedOption)=>SlectHandler(selectedOption)} 
                        />
                    </div>
                    <div className="Attach">
                       <label html="Attachment" className="input_Attach">
                        <label className="FileSercher">
                        <div className="Lab">Резюме</div>

                        <label className="input__file-button-text">{state.fileName}</label>
                        </label>
                        <div className="FileSercher GG">
                        <label className="Lab Acc">.doc, .docx, .pdf</label>
                        
                       
                        <span className="input__file-icon-wrapper"><img className="input__file-icon" src={pepe} alt=""/> Прикрепить файл </span>
                        <input id="file" className="Attachment" onChange={(e) => {setState({fileName: e.target.files[0].name})}} name="Attachment"  type="file" accept=".doc,.docx,.pdf"></input>
                        </div>
                        </label>
                    </div>
                </div>
                <div className="AboutYourself">
                    <div className="Lab">О себе</div>
                    <textarea id="About" name="About" className="LabFix" type="text">
                    </textarea>
                </div>
                
            </div>
            <button name="Submit" type="submit" className="Submit" onClick={Submit}><p>Отправить</p> <img src={arrow} alt=""/></button>
            
           
            
        </form>

        <div className={openModal ? "ForModal Open" : "ForModal"} >
        <div className="Modal" style={{display: openModal ? "flex" : "none"}}>
            <div className="TextContent">
            <div className="MHeader">Ваше резюме отправлено!</div>
            <div className="MBody">Спасибо за вашу заявку. Мы свяжемся с вами, если ваш профиль соответствует нашим требованиям.</div>
            </div>
            <div className="MButton">
                <button onClick={() => setOpenModal(false)} className="Ok">Понятно</button>
            </div>
            </div>
            </div>

            <div className={errModal ? "ForModal Open" : "ForModal"} >
            <div className="Modal err" style={{display: errModal ? "flex" : "none"}}>
            <div className="TextContent">
            <div className="MHeader">Что-то пошло не так</div>
            <div className="MBody">Одно из полей заполнено некорректно. Пожалуйста, заполните правильно</div>
            </div>
            <div className="MButton">
                <button onClick={() => setErrModal(false)} className="Ok">Понятно</button>
            </div>
            </div>
            </div>

            <div className={sendMod ? "ForModal Open" : "ForModal"} >
            <div className="Modal" style={{display: sendMod ? "flex" : "none"}}>
            <div className="TextContent">
            <div className="MHeader">Форма уже отправлена</div>
            <div className="MBody">Спасибо за отклик, наши специалисты с Вами свяжутся в ближайшее время</div>
            </div>
            <div className="MButton">
                <button onClick={() => setSendMod(false)} className="Ok">Понятно</button>
            </div>
            </div>
            </div>
        </div>
        
        
        
    )
}

export default FormToReply
