import React, { useEffect, useState } from "react";
import chmo from "../Component/css/images/Chmo.svg"
import Vacansies from "../Vacansies";


const MoreButton =({setSelectedOption, ItName, setOptionNum, vacName, setPlace})=>{
  
  const options = Vacansies.vacansies
  const VacansActive =()=>{
        document.querySelector("#About").defaultValue = "Добрый день, меня заинтересовала вакансия на должность " + vacName
        setOptionNum(ItName)
        setSelectedOption(options[ItName])
    }
    const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
    [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
    onWindowResize = () => {              
      clearTimeout(window.resizeLag)
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag                       
        setOrientation(isLandscape() ? 'landscape' : 'portrait')
      }, 200)
    }
  
  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
    ),[])

    return(
        <div className="BMore" onClick={ () =>{VacansActive()}}>{ 
            (window.innerWidth <=768) ? "Откликнуться" : "Откликнуться на вакансию"}
            <img className="chmo" src={chmo} alt=""/>
        </div>
    )
}

export default MoreButton