import React from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyCopied from "./MyCopied";



const ClipBoard =({copyText})=>{


    
    const notify = () => toast(<MyCopied/>,{
        
        position: toast.POSITION.TOP_CENTER,
        limit:0, 
        closeOnClick: false
    });


    return(
        <div>
            <CopyToClipboard text={copyText}>
                <button onClick={notify} className="NumNote"></button>
            </CopyToClipboard>
            <ToastContainer autoClose={1000} closeButton={false} hideProgressBar={true} bodyClassName={"Copied"}/>
        </div>
       
    )
}

export default ClipBoard