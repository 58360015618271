export default {
    events:[
        {
            image: 'url("/Images/TakerSS.webp")',
            images: '/Images/TakerSS.webp',
            name:  'Грузоперевозящая техника',
            description: ["ПП - 67 ед", "ППЦ - 57 ед", "Тягачи - 165 ед"],
            subDesc:["«КАМАЗ» - 136 ед", "«SCANIA» - 19 ед", "«MAN» - 5 ед", "«FAW» - 5 ед"],
            descHeader:["Тягач седельный:"]
        },
        {
            image: 'url("/Images/SpecialMob.png")',
            images: "/Images/SpecialMob.png",
            name:  'Специализированная техника',
            description: ["ППУ - 5 ед", "ЦА и компрессорная установка - 8 ед", "Автоцистерны для тех/пит воды - 13 ед", "Вахтовки - 20 ед"],
        },
        {
            image: 'url("/Images/ToyotaMob.png")',
            images: "/Images/ToyotaMob.png",
            name:  'Оперативный транспорт',
            description: ["Toyota Land Cruiser - 5 ед", "Renault Duster - 7 ед", "Chevrolet Niva  - 3 ед"],
        },
        {
            image: 'url("/Images/GroundS.webp")',
            images: "/Images/GroundS.webp",
            name:  'Землеройная техника',
            description: ["Бульдозеры - 16 ед", "Погрузчики - 19 ед", "Самосвалы - 4 ед"],
        },
        {
            image: 'url("/Images/AutoS.webp")',
            images: "/Images/AutoS.webp",
            name:  'АвтоКраны',
            description: ["25т - 43 ед", "40т - 5 ед", "50т - 13 ед", "КМУ - 5 ед"],
        }
    ]
}