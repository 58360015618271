import React from 'react';
import { HashLink } from 'react-router-hash-link';





const Button =({ clas, value, anchor})=>{


   
 
    return(
        <div >
           <HashLink className={clas} to={anchor}>{value}</HashLink>
        </div>
    )
    
}

export default Button