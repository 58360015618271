import React from "react";
import './css/TechCard.css'


export default function TechCard({image, name, description }) {

    const listDesc =  description.map((value, index) => {
       return <li key={name + index}>{value}</li>                
   
    })
    

    return (
      <>
        <div className="Article">
          <div className="TechImages" style={{'--Imgur': image}}>
          </div>
          <div className="DescriptWrapp">
            <div className="DescriptHeader">
                {name}
            </div>
            <div className="DescriptBody">
                <ul>{listDesc}</ul>
            </div>
          </div>
        </div>
      </>
    );
  }