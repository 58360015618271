
import React, { useEffect, useState } from "react";
import './css/ThirdBlock.css'
import TechElemBW from "./TechElemBW";
import TechElemSW from "./TechElemSW";
import { Link } from "react-router-dom";
import { TECH_ROUTE } from "../utils/consts";
import arrow from "./css/images/ArrowRight.svg"



const ThirdBlock =()=>{
    
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      const handleResize = () => {
        setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        });
      }
      useEffect(() => {
      window.addEventListener("resize", handleResize, false);
      }, []);

    return(
        <div className="ThirdBlock" id="Techs">
            <div className="ThirdWrapper">
                <div className="ThirdHeader">НАША ТЕХНИКА
                    <Link to={TECH_ROUTE} className="LinkTo"><p>Смотреть все</p><img src={arrow} alt=""/></Link>
                </div>
                {
                    (window.innerWidth <= 480) ? <TechElemSW/> : <TechElemBW/>
                
                }
                 <Link to={ TECH_ROUTE} className="LinkToMob"><p>Смотреть все</p><img src={arrow} alt=""/></Link>
            </div>
        </div>
    )
}


export default ThirdBlock