export default {
    vacansies:[
        {
            label:"Выберите подходящую вакансию",
            value:"Выберите подходящую вакансию",
        },
        {
            label:"Стропальщики",
            value:"Стропальщики",
            price:"130 860 ₽",
        },
        {
            label:"Слесаря, Кладовщики, Электрики",
            value:"Слесаря, Кладовщики, Электрики",
            price:"134 490 ₽",
        },
        {
            label:"Охранник КПП, Снабженец, Заправщик автомобилей",
            value:"Охранник КПП, Снабженец, Заправщик автомобилей",
            price:"134 490 ₽",
        },
        {
            label:"Водянщики",
            value:"Водянщики",
            price:"138 120 ₽",
        },
        {
            label:"Машинист крана автомобильного 25тн",
            value:"Машинист крана автомобильного 25тн",
            price:"214 350 ₽",
        },
        {
            label:"Машинист крана автомобильного 40 тн, 50тн, 60тн",
            value:"Машинист крана автомобильного 40 тн, 50тн, 60тн",
            price:"239 760 ₽",
        },
        {
            label:"Машинист экскаватора Hitachi ZX330",
            value:"Машинист экскаватора Hitachi ZX330",
            price:"221 610 ₽",
        },
        {
            label:"Машинист автогрейдера",
            value:"Машинист автогрейдера",
            price:"221 610 ₽",
        },
        {
            label:"Водитель погрузчика, машинист Бульдозера",
            value:"Водитель погрузчика, машинист Бульдозера",
            price:"185 310 ₽",
        },
        {
            label:"Водитель Экскаватор-погрузчика ав 2574",
            value:"Водитель Экскаватор-погрузчика ав 2574",
            price:"167 160 ₽",
        },
        {
            label:"Водитель Мини-погрузчика (JCB)",
            value:"Водитель Мини-погрузчика (JCB)",
            price:"167 160 ₽",
        },
        {
            label:"Машинист Компрессорных установок",
            value:"Машинист Компрессорных установок",
            price:"203 460 ₽",
        },
        {
            label:"Машинист цементеровочного агрегата 320",
            value:"Машинист цементеровочного агрегата 320",
            price:"185 310 ₽",
        },
        {
            label:"Машинист цементеровочного агрегата 700, 1000",
            value:"Машинист цементеровочного агрегата 700, 1000",
            price:"203 460 ₽",
        },
        {
            label:"Водитель категории СЕ",
            value:"Водитель категории СЕ",
            price:"203 460 ₽",
        },
        {
            label:"Водитель Топливозаправщика",
            value:"Водитель Топливозаправщика",
            price:"167 160 ₽",
        },
        {
            label:"Водитель категории СЕ (Тралл)",
            value:"Водитель категории СЕ (Тралл)",
            price:"221 610 ₽",
        },
        {
            label:"Водитель крана - манипулятора",
            value:"Водитель крана - манипулятора",
            price:"203 460 ₽",
        },
        {
            label:"Машинист Снегоболотохода (трэкол, хищник)",
            value:"Машинист Снегоболотохода (трэкол, хищник)",
            price:"185 310 ₽",
        },
        {
            label:"Машинист Самосвала, ППУ, вакуумной уствновки, водитель вахтового автобуса",
            value:"Машинист Самосвала, ППУ, вакуумной уствновки, водитель вахтового автобуса",
            price:"167 160 ₽",
        },
        {
            label:"Водитель КДМ",
            value:"Водитель КДМ",
            price:"167 160 ₽",
        },
        {
            label:"Водитель автомобиля пит. воды Водовозка",
            value:"Водитель автомобиля пит. воды Водовозка",
            price:"167 160 ₽",
        },
        {
            label:"Водитель автомобиля Тех. Воды",
            value:"Водитель автомобиля Тех. Воды",
            price:"203 460 ₽",
        },
        {
            label:"Водитель автомобиля класса газ егерь",
            value:"Водитель автомобиля класса газ егерь",
            price:"149 010 ₽",
        },
    ]
}

