import React from "react";
import './MyCopie.css'


const MyCopied =()=>{



    return(
        <div className="CopieWrapp">
            Скопировано
        </div>
    )
}

export default MyCopied