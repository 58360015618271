import index from "./pages/IndexPage"
import tech from "./pages/TechPage"
import vacans from "./pages/Vacans"
import { TECH_ROUTE, VACANS_ROUTE, INDEX_ROUTE} from "./utils/consts"

export const publicroutes = [
    {
        path: INDEX_ROUTE,
        component: index  
    },
    {
        path: TECH_ROUTE,
        component: tech
    },
    {
        path: VACANS_ROUTE,
        component: vacans,
    }
]