import React from "react";
import './css/Footer.css'
import Button from "../Ui/Button";
import {YMInitializer} from 'react-yandex-metrika';



const Footer =()=>{



    return(
        <div className="Footer">
            <YMInitializer accounts={[94992834]} options={{clickmap:true,trackLinks:true,accurateTrackBounce:true,webvisor:true}}/>
            <div className="FooterWrapper">
                <div className="LogoWrapper">
                    <div className="LogoF"></div>
                    <div className="ComDescript">
                        <p>ООО «СибАвтоКом»</p>
                        <p>ОГРН 1135543025155</p>
                        <p>ИНН 5505217847</p>
                        <p>КПП 550501001</p>
                        <p>Россия, Омск, 644121</p>
                        <p> ул. 25 лет Октября, д. 47</p>
                    </div>
                    <div className="Ltd">© 2013 - 2023 
                    <p>Все права защищены</p></div>
                </div>
                <div className="NavWrapp">
                    <div className="NavHeader">Основные разделы</div>
                    <div id="Footer" className="NavPanel" style={{color:'#FFF', alignItems:'flex-start'}}>
                        <Button fu={null} clas={"PointFooter"} value={"О нас"} anchor="/#Us"/>
                        <Button fu={null} clas={"PointFooter"} value={"Техника"} anchor="/#Techs"/>
                        <Button fu={null} clas={"PointFooter"} value={"Для заказчиков"} anchor="/#Byer"/>
                        <Button fu={null} clas={"PointFooter"} value={"Для устройства на работу"} anchor="/#Employ"/>
                    </div>
                </div>
                <div className="LocationsWrapper">
                    <div className="LockHeader">Расположение</div>
                    <div className="LockWrapFoot">
                    <div className="Office">
                        <div className="OfHead">Офис компании:</div>
                        <div className="LocationF"><div className="GeoLogoF"></div><div className="GeoTextF">г. Омск, улица 19 Амурская 69А.</div></div>
                    </div>
                    <div className="Office">
                        <div className="OfHead">Площадка:</div>
                        <div className="LocationF"><div className="GeoLogoF"></div><div className="GeoTextF">г. Новый Уренгой</div></div>
                    </div>
                    </div>
                    
                </div>
                <div className="Contacts">
                    <div className="Cont">Контакты</div>
                    <div className="NumWrappFoot">
                    <div className="ConnectFoot">
                    <div className="ContH">Для связи:</div>
                    <div className="InfoWrapperF">
                        <div className="PhoneF"></div>
                        <div className="PhoneInfoF"><a href="tel:+7 913 978-70-00">+7 913 978-70-00 (Владислав)</a></div>
                    </div>
                    </div>
                    <div className="ConnectFoot">
                    <div className="ContH">Для трудоустройства:</div>
                    <div className="NumbersInfoF">
                        <div className="InfoWrapperF">
                            <div className="PhoneF"></div>
                            <div className="PhoneInfoF"><a href="tel:+7 965 975-30-20">+7 965 975-30-20 (Владимир)</a></div>
                        </div>
                        <div className="InfoWrapperF">
                            <div className="PhoneF"></div>
                            <div className="PhoneInfoF"><a href="tel:+7 913 972-32-32">+7 913 972-32-32 (Антон)</a></div>
                        </div>
                    </div>
                    </div>
                    
                    </div>
                    
                </div>
            </div>
        </div>
    )

}

export default Footer
