import React from "react";
import './css/FivethBlock.css'
import ClipBoard from "../Ui/ClipBoard";

const FivethBlock =()=>{



    return(
        <div className="FivethBlock">
            <div className="FivethWrapper">
                <div className="WorkWrapper">
                    <div className="WorkHeader">Условия работы</div>
                    <div className="WorkBody">
                        <ul>
                            <li>Вахтовый метод 45/45. При согласовании, имеется возможность работать 60/60.</li>
                            <li>Официальное трудоустройство</li>
                            <li>Оплачиваемый проезд в обе стороны</li>
                            <li>Суточные</li>
                            <li>Спецодежда, медкомиссия за счет организации</li>
                        </ul>
                    </div>
                </div>
                <div className="Numbers">
                    <div className="NumWrapper">
                        <div className="NumHeader">По поводу трудоустройства с нами можно связаться по телефонам:</div>
                        <div className="NumbersInfo">
                            <div className="InfoWrapper">
                                <div className="Phone"></div>
                                <div className="PhoneInfo"><a href="tel:+7 965 975-30-20">+7 965 975-30-20 (Владимир)</a></div>
                                <ClipBoard copyText={"+7 965 975-30-20"}/>
                            </div>
                            <div className="InfoWrapper">
                                <div className="Phone"></div>
                                <div className="PhoneInfo"><a href="tel:+7 913 972-32-32">+7 913 972-32-32 (Антон)</a></div>
                                <ClipBoard copyText={"+7 913 972-32-32"}/>
                            </div>
                        </div>
                        <div className="NumberFooter">С понедельника по пятницу с 10:00 до 18:00</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FivethBlock