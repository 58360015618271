import React, { useEffect, useState } from "react";
import TechList from "../Component/TechList";
import NavBarMobileTech from "../Component/NavBarMobileTech";
import NavBarTech from "../Component/NavBarTech";
import ButtonTop from "../Ui/ButtonTop";



const TechPage =()=>{
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
    });
  }
  useEffect(() => {
  window.addEventListener("resize", handleResize, false);
  }, []);


  const param = 1
    const [isActive, setActive] = useState("false");
  
    return(
        <>
             {
         (window.innerWidth <=481) ? <NavBarMobileTech isActive={isActive} setActive={setActive}/> : <NavBarTech isActive={isActive} setActive={setActive}/>
        }
        <ButtonTop/>
        <TechList/>
        </>
    )
}

export default TechPage