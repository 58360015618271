import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import YMInitializer from 'react-yandex-metrika/lib/init'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />  
);
