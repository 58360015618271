import React from "react";
import './css/SecondBlock.css'


const SecondBlock =()=>{


    return(
        <div className="SecondBlock" id="Byer">
            <div className="SecondWrapper">
                <div className="HeaderSecond">НАШИ ПРЕИМУЩЕСТВА</div>
                <div className="BodyWrapperSec">
                    <div className="PerspectWrap">
                        <div className="PerspHeader">
                            <div className="PerspectIcon" style={{backgroundImage: `url(/Images/Car.svg)`}}></div>
                            <div className="PerspHText">ПАРК ПОДВИЖНОГО СОСТАВА</div>
                        </div>
                        <div className="PerspBody">ООО «СибАвтоКом» обладает парком из более 400 единиц разнообразной техники. При необходимости приобретаем новые машины, чтобы соответствовать требованиям заказчика.</div>
                    </div>
                    <div  className="PerspectWrap">
                        <div className="PerspHeader">
                            <div className="PerspectIcon"  style={{backgroundImage: `url("/Images/Check.svg")`}}></div>
                            <div className="PerspHText">ПОДДЕРЖКА</div>
                        </div>
                        <div className="PerspBody">Мы предоставляем автомобили, адаптированные под климат региона. Наши машины оборудованы для эксплуатации в районах крайнего Севера и бездорожья.</div>
                    </div>
                    <div  className="PerspectWrap">
                        <div className="PerspHeader">
                            <div className="PerspectIcon"  style={{backgroundImage: `url("/Images/Hat.svg")`}}></div>
                            <div className="PerspHText">ПРОФЕССИОНАЛИЗМ</div>
                        </div>
                        <div className="PerspBody">Все наши водители — профессионалы, обученные зимнему и защитному вождению в собственном классе БДД, гарантируя безопасность на дороге.</div>
                    </div>
                    <div  className="PerspectWrap">
                        <div className="PerspHeader">
                            <div className="PerspectIcon"  style={{backgroundImage: `url("/Images/Star.svg")`}}></div>
                            <div className="PerspHText">ЧЕСТНОСТЬ</div>
                        </div>
                        <div className="PerspBody">ООО «СибАвтоКом» дорожит своей репутацией: отсутствие судебных разбирательств по срокам и качеству выполняемых работ, надежное финансовое состояние и честный подход к бизнесу.</div>
                    </div>
                    <div  className="PerspectWrap">
                        <div className="PerspHeader">
                            <div className="PerspectIcon"  style={{backgroundImage: `url("/Images/Setting.svg")`}}></div>
                            <div className="PerspHText">КОНТРОЛЬ</div>
                        </div>
                        <div className="PerspBody">«СибАвтоКом» использует передовые системы контроля, включая «ГЛОНАСС» и «АСК», для мониторинга и безопасности своего автопарка.</div>
                    </div>
                    <div  className="PerspectWrap">
                        <div className="PerspHeader">
                            <div className="PerspectIcon"  style={{backgroundImage: `url("/Images/Home.svg")`}}></div>
                            <div className="PerspHText">УСЛОВИЯ ЖИЗНИ РАБОЧИХ</div>
                        </div>
                        <div className="PerspBody">  Проживание рабочих происходит в собственных вагон-городках, что снимает лишние проблемы с наших заказчиков.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondBlock