import React from "react";
import arrow from "../Component/css/images/ArrowTop.svg"
import "./ArrowTop.css"
import {Link} from "react-scroll"
import { useState } from "react";
import { useEffect } from "react";

const ButtonTop = () => {

    const [scroll, setScroll] = useState(0);

    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);

    return(
        <Link to="nav">
        <div className="ButtonTopWrapp" style={scroll >= 100 ? {opacity:"1"} : {opacity:"0"}}>
            <img className="ArrowTop" src={arrow}></img>
        </div>
        </Link>
    )
}

export default ButtonTop