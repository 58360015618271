import React from "react";
import './css/NavBar.css'
import Button from "../Ui/Button";
import FirstVacansBlock from "./FirstVacansBlock";

const NavBarVac=({setActive, isActive})=>{
    const handleToggle = () => {
        setActive(!isActive);
        isActive ? document.body.style.overflow = "hidden" : document.body.style.overflow = "scroll"
    };
    
    return(
        <>
            <div className="NavBar" id="nav">
            
            <div className="NavBarConteiner">
            <a id="menu"></a>
            <a href="/" aria-label="Link to main page">
            <div className="Logo"></div>
            </a>
            <div className={`menu-burger__header ${!isActive ? "open-menu ": ""}`} onClick={handleToggle}>
                <span></span>
            </div>

        </div>
            <div className="NavWrapper">
                <a href="/" aria-label="Link to main page">
                <div className="Logo"></div>
                </a>
                <div className="NavPanel">
                    <Button fu={null} clas={"Points"} value={"О нас"} anchor="/#Us"/>
                    <Button fu={null} clas={"Points"} value={"Для заказчиков"} anchor="/#Byer" />
                    <Button fu={null} clas={"Points"} value={"Техника"} anchor="/#Techs"/>
                    <Button fu={null} clas={"Points"} value={"Для устройства на работу"} anchor="/#Employ"/>
                </div>
            </div> 
        </div>
        <FirstVacansBlock/>
        </>
        
    )
}

export default NavBarVac