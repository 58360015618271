import React, { useEffect, useState } from "react";
import './css/TechWrapper.css'


const TechElemBW =()=>{
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      const handleResize = () => {
        setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        });
      }
      useEffect(() => {
      window.addEventListener("resize", handleResize, false);
      }, []);


    return(
        <div>
            {
                (window.innerWidth > 1000) ? <div className="TechWrapper">
                    <div className="HeaderTechs">
                        <div className="HeaderTech" style={{backgroundImage:"url('/Images/AutoKran.webp')"}}>
                        <div className="BodyTBodyWrapp">
                            
                            <div className="HeadTHeader">АвтоКраны</div>
                            <div className="BodyTBody">
                            <ul>
                                <li>25т - 43 ед</li>
                                <li>40т - 5 ед</li>
                                <li>50т - 13 ед</li>
                                <li>КМУ - 5 ед</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="HeaderTech" style={{backgroundImage:"url('/Images/GroundDestroer.webp')"}}>
                        <div className="BodyTBodyWrapp">
                            
                            <div className="HeadTHeader" >Землеройная техника</div>
                            <div className="BodyTBody">
                            <ul>
                                <li>Бульдозеры - 16 ед</li>
                                <li>Погрузчики - 19 ед</li>
                                <li>Самосвалы - 4 ед</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="BodyTechs">
                        <div className="BodyTech"  style={{backgroundImage:"url('/Images//SpecialTech.webp')"}}>
                            <div className="BodyTBodyWrapp">
                                
                                <div className="BodyTHeader">Специализированная техника</div>
                                <div className="BodyTBody">
                                <ul>
                                <li>ППУ - 5 ед</li>
                                <li>ЦА и компрессорная установка - 8 ед</li>
                                <li>Автоцистерны для тех/пит воды - 13 ед</li>
                                <li>Вахтовки - 20 ед</li>
                                </ul>
                                </div>
                        </div>
                        
                    </div>
                    <div className="BodyTech" id="Toy"  style={{backgroundImage:"url('/Images/Toyota.webp')"}}>
                        <div className="BodyTBodyWrapp">
                            <div className="BodyTHeader">Оперативный транспорт</div>
                            <div className="BodyTBody">
                            <ul>
                                <li>Toyota Land Cruiser - 5 ед</li>
                                <li>Renault Duster - 7 ед</li>
                                <li>Chevrolet Niva  - 3 ед</li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div className="BodyTech" style={{backgroundImage:"url('/Images/Taker.webp')"}}>
                        <div className="BodyTBodyWrapp">
                            
                            <div className="BodyTHeader">Грузоперевозящая техника</div>
                            <div className="BodyTBody">
                                <ul>
                                <li>ПП - 67 ед</li>
                                <li>ППЦ - 57 ед</li>
                                <li>Тягачи - 165 ед</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                 :
                 <div className="TechWrapper">
                    <div className="HeaderTechs">
                        <div className="HeaderTech" style={{backgroundImage:"url('/Images/AutoKranS.webp')"}}>
                        <div className="BodyTBodyWrapp">
                            <div className="HeadTHeader">АвтоКраны</div>
                            <div className="BodyTBody">
                            <ul>
                                <li>25т - 43 ед</li>
                                <li>40т - 5 ед</li>
                                <li>50т - 13 ед</li>
                                <li>КМУ - 5 ед</li>
                            </ul>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                    <div className="BodyTechs">
                        
                        <div className="BodyTech" style={{backgroundImage:"url('/Images/TakerSmall.png')"}}>
                            <div className="BodyTBodyWrapp">
                                <div className="BodyTHeader">Грузоперевозящая техника</div>
                                <div className="BodyTBody">
                                <ul>
                                <li>ПП - 67 ед</li>
                                <li>ППЦ - 57 ед</li>
                                <li>Тягачи - 165 ед</li>
                                </ul>
                                </div>
                        </div>
                        
                    </div>
                    <div className="BodyTech" style={{backgroundImage:"url('/Images/SpecialSmall.webp')"}}>
                        <div className="BodyTBodyWrapp">
                            <div className="BodyTHeader">Специализированная техника</div>
                            <div className="BodyTBody">
                                <ul>
                                <li>ППУ - 5 ед</li>
                                <li>ЦА и компрессорная установка - 8 ед</li>
                                <li>Автоцистерны для тех/пит воды - 13 ед</li>
                                <li>Вахтовки - 20 ед</li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                    <div className="BodyTech" style={{backgroundImage:"url('/Images/ToyotaSmall.webp')"}}>
                        <div className="BodyTBodyWrapp">
                            <div className="BodyTHeader">Оперативный транспорт</div>
                            <div className="BodyTBody">
                            <ul>
                                <li>Toyota Land Cruiser - 5 ед</li>
                                <li>Renault Duster - 7 ед</li>
                                <li>Chevrolet Niva  - 3 ед</li>
                            </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div className="BodyTech" style={{backgroundImage:"url('/Images/GroundDestroerS.webp')"}}>
                        <div className="BodyTBodyWrapp">
                            <div className="BodyTHeader">Землеройная техника</div>
                        <div className="BodyTBody">
                            <ul>
                                <li>Бульдозеры - 16 ед</li>
                                <li>Погрузчики - 19 ед</li>
                                <li>Самосвалы - 4 ед</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
                </div>
            }
            
        </div>
    )
}

export default TechElemBW