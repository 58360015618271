import React from "react";


const FirstTechMob =()=>{

    return(
        <div className="FirstBlock">
        <div className="FirstWrapper"> 
        <div className="HeaderWrapper">
        <div className="TextHeader tech">НАША 
        <p>ТЕХНИКА</p></div>
        <div className="PointsWrapper tech">
            <div className="LogoPoint">
            <img src="./Images/TechLogo.png" alt=""></img>
            </div>
            <div className="TextPoint">
            <img src="./Images/TextLogoPoint.webp" alt=""></img>
            </div>
        </div>
        </div>
    </div>
  
    </div>
    )
}

export default FirstTechMob