
import './App.css';
import { BrowserRouter} from "react-router-dom";
import AppRouter from './Component/AppRouter'
import Footer from './Component/Footer';
import ScrollToTop from './Helpers/ScrollToTop';




function App() {
 
  return (
    <>
    <div className='App'>
    
    <BrowserRouter>
      <ScrollToTop/>
      <AppRouter/>
      <Footer/>
    </BrowserRouter>
    </div>
    </>
  );
}



export default App;
