import React from "react";
import './css/FourthBlock.css'
import { Link } from "react-router-dom";
import { VACANS_ROUTE } from "../utils/consts";
import arrow from "./css/images/ArrowRight.svg"
import { useState } from "react";
import { useEffect } from "react";
import VacLi from "./VacLi";
import VacLiMob from "./VacLiMob";


const FourthBlock =({selectedOption, setSelectedOption, setOptionNum, setPlace})=>{

    const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
    [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
    onWindowResize = () => {              
      clearTimeout(window.resizeLag)
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag                       
        setOrientation(isLandscape() ? 'landscape' : 'portrait')
      }, 200)
    }
  
  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
    ),[])


    return(
        <div className="FourthBlock" id="Employ">
            
            <div className="FourthWrapper">
                <div className="FourthHeader">АКТУАЛЬНЫЕ ВАКАНСИИ
                <Link to={ VACANS_ROUTE} className="LinkTo"><p>Смотреть все</p><img src={arrow} alt=""/></Link>
                </div>
                    {
                        (window.innerWidth <=768) ? <VacLiMob setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/> : <VacLi setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
                    }
                    <Link to={ VACANS_ROUTE} id="Vac" className="LinkToMob"><p>Смотреть все</p><img src={arrow} alt=""/></Link>
            </div>
        </div>
    )
}

export default FourthBlock