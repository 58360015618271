import React from "react";
import './css/NavBarMobile.css'
import FirstVacMob from "./FirstVacMob";
import ButtonMob from "../Ui/ButtonMob";


const NavBarMobileVac =({setActive, isActive})=>{

    
    const handleToggle = () => {
        setActive(!isActive);
        isActive ? document.body.style.overflow = "hidden" : document.body.style.overflow = "scroll"
    };

    return(
        <div className={`NavBar ${!isActive ? "open-menu": ""}`} id="nav">
           
        <div className="NavBarConteiner">
            <a href="/" aria-label="Link to main page">
            <div className="Logo"></div>
            </a>
            <div className={`menu-burger__header ${!isActive ? "open-menu ": ""}`} onClick={handleToggle}>
                <span></span>
            </div>

        </div>
        <div className={`NavWrapper ${!isActive ? "open-menu": ""}`}>
                <div className={`NavPanel ${!isActive ? "open-menu": ""}`}>
                <ButtonMob fu={handleToggle} clas={"Points"} value={"О нас"} anchor="/#Us" isActive={isActive} setActive={setActive}/>
                <ButtonMob fu={handleToggle} clas={"Points"} value={"Для заказчиков"} anchor="/#Byer" isActive={isActive} setActive={setActive}/>
                <ButtonMob fu={handleToggle} clas={"Points"} value={"Техника"} anchor="/#Techs" isActivePage={isActive} setActive={setActive}/>
                <ButtonMob fu={handleToggle} clas={"Points"} value={"Для устройства на работу"} anchor="/#Employ" isActive={isActive} setActive={setActive}/>
                </div>
                </div>
        <FirstVacMob isActive={isActive} setActive={setActive}/>
        
       </div>
    )
}

export default NavBarMobileVac