import React, { useEffect, useState } from "react";
import imgData from "../TechsData.js";
import "./css/TechList.css"
import { Link } from "react-router-dom";
import arrow from "./css/images/ArrowWhite.svg"
import arrowBack from "./css/images/ArrowBack.svg"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SmallArr from "./css/images/ArrowPrev.svg"
import "./css/TechList.css"


const TechList =()=>{
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      const handleResize = () => {
        setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        });
      }
      useEffect(() => {
      window.addEventListener("resize", handleResize, false);
      }, []);

    const Techs = imgData.events
    
    return(
        <div className="Techs"> 

        <Link className="BackButton" to="/">
            
            <img src={arrowBack} alt=""/>
            <p>
            Назад
            </p>
            </Link>
        <div className="TechnicWrapp">
            {
                Techs.map((items, index) => {
                    return(
                        <div className="CardWrapper" key={index + items.name}>
                                
                                <div className="Img" >
                                    <Carousel style={{overflow:"visible"}} centerSlidePercentage={100} centerMode={true}  showStatus={false} showArrows={true} showThumbs={false} infiniteLoop className="SliderWrapper"   id={index}
                                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                                            const defStyle = {cursor: "pointer", zIndex:"100", color:"#E3E3E3", opacity:"0.5" };   
                                            const style = isSelected
                                              ? { ...defStyle, color: "#486ADA" , opacity: "1" }
                                              : { ...defStyle };
                                            return (
                                              <span
                                                style={style}
                                                onClick={onClickHandler}
                                                onKeyDown={onClickHandler}
                                                value={index}
                                                key={index}
                                                role="button"
                                                tabIndex={0}
                                                aria-label={`${label} ${index + 1}`}
                                              >
                                              </span>
                                            );
                                          }}
                                          renderArrowPrev={(clickHandler) =>{
                                            return (
                                                <button 
                                                  onClick={clickHandler}
                                                  type="button"
                                                  className="PrevButt"
                                                >
                                                    <img id="PrevImg" src={SmallArr} alt=""/>
                                                </button>
                                              );
                                        }}
                                          renderArrowNext={(clickHandler) =>{
                                            return (
                                                <button
                                                  onClick={clickHandler}
                                                  type="button"
                                                  className="NextButt"
                                                >
                                                    <img id="NextImg" src={SmallArr} alt=""/>
                                                </button>
                                              );
                                          }}
                                         
                                    > 
                                    {
                                    (items.images).map((value, index) => (
                                        <div style={{padding:"1px"}} className="ImgConteiner" key={ (index * 2487561827365) + items.name}>
                                            <img style={{width: "100%", height: "100%"}}   src={value} alt="" />
                                        </div>
                                        )
                                    )} 
                                    </Carousel>
                                    
                                </div>
                                <div className="contentWrapp">
                                <div className="Title">{items.name}</div>
                                <div className="BBody">{items.body} </div>
                                <div className="BodyWrapp">
                                    {
                                        (items.description).map((desc, index) =>(
                                            <ul className="TechUl" key={index * 364123684126}>
                                                <li className="TechLi">{desc}</li>
                                            </ul>
                                        ))
                                    }
                                    </div>
                                    </div>
                                    <Link className="More" to="#" > Подробнее <img src={arrow} alt=""/></Link>
                                
                        </div>
                    )
                })
            }
        </div>
        </div>
    )
}

export default TechList