import React from 'react'
import Marquee from 'react-fast-marquee'
import './css/RSS.css'
import ShotMarquee from '../Ui/ShotMarquee'
import MarqData from '../MarqData'

const RSS =()=>{

   const items = MarqData.shots

    return(
        <div className='RSS'>
            <a name="Employ"></a>
            <div className='RSSHeader'>НАС ВЫБИРАЮТ</div>
            <div className='marqueeOuter'>
                <Marquee speed={125} autoFill={true}  gradient={false} pauseOnClick={false} direction={'left'}>
                    {
                     items.map((item, index)=>(
                        <div key={index} className="Shot">
                            <ShotMarquee {...item}/>
                        </div>
                    ))
                    }
                </Marquee>
            </div>

        </div>
    )
}

export default RSS