import React from "react";




const ShotMarquee =({imgGay, imgCal, w})=>{


    return(
        <div className="ShotR" style={{width: w}}>
            <img className="Gay" src={imgGay} style={{width: w, height:"90px"}} alt=""></img>
            <img className="Cal" src={imgCal} style={{width: w, height:"90px"}} alt=""></img>
        </div>
    )
}

export default ShotMarquee