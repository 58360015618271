import React from "react";
import arrow from "./css/images/ArrowWhite.png"
import MoreButton from "../Ui/MoreButton";
import { Link } from "react-router-dom";



const VacLiMob =({selectedOption, setSelectedOption, setOptionNum, setPlace})=>{



    return(
        <div className="VacansWrapper">
        <div className="Vacans">
            <div className="VacansName">Слесарь, Кладовщик, Электрик
            <div className="MoreInfo" >
            <a href="#form">
           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption} vacName={"Слесарь, Кладовщик, Электрик"} ItName={2}/>
           </a>
            <Link className="RoadMore" to="#">
                Подробнее
                <img className="ArrowMore" src={arrow} alt=""/>
            </Link>
        </div>
            </div>
            <div className="VacansPrice"> 130 860 ₽</div>
           
        </div>
        <div className="Vacans">
            <div className="VacansName">Охранник КПП, Снабженец, Заправщик автомобилей
            <div className="MoreInfo" >
            <a href="#form">
            <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption} vacName={"Охранник КПП, Снабженец, Заправщик автомобилей"} ItName={3}/>
            </a>
             <Link className="RoadMore" to="#">
                 Подробнее
                 <img className="ArrowMore" src={arrow} alt=""/>
             </Link>
         </div>
            </div>
            <div className="VacansPrice"> 134 490 ₽</div>
  
        </div>
        <div className="Vacans">
            <div className="VacansName">Водянщики
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption} vacName={"Водянщики"} ItName={4}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
            </div>
            <div className="VacansPrice"> 138 120 ₽</div>
           
        </div>
        <div className="Vacans">
            <div className="VacansName">Машинист крана автомобильного 25тн
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption} vacName={"Машинист крана автомобильного 25тн"} ItName={5}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
            </div>
            <div className="VacansPrice">239 760 ₽</div>
           
        </div>
        <div className="Vacans">
            <div className="VacansName">Машинист экскаватора Hitachi ZX330
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption} vacName={"Машинист экскаватора Hitachi ZX330"} ItName={6}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
            </div>
            <div className="VacansPrice">221 610 ₽</div>
            
        </div>
        <div className="Vacans">
            <div className="VacansName">Машинист автогрейдера
            <div className="MoreInfo" >
            <a href="#form">
                           <MoreButton setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption} vacName={"Машинист автогрейдера"} ItName={7}/>
                           </a>
                            <Link className="RoadMore" to="#">
                                Подробнее
                                <img className="ArrowMore" src={arrow} alt=""/>
                            </Link>
                        </div>
            </div>
            <div className="VacansPrice"> 221 610 ₽</div>
            
        </div>
    </div>
    )
}

export default VacLiMob