import React from "react";




const FirstVacansBlock = () =>{



    return(
        <div className="FirstBlock">
            <div className="FirstWrapper"> 
        <div className="HeaderWrapper">
        <div className="TextHeader tech">АКТУАЛЬНЫЕ 
        <p>ВАКАНСИИ</p></div>
        <div className="PointsWrapper vac">
            <div className="LogoPoint">
            <img src="./Images/VacansLogo.png" alt=""></img>
            </div>
            <div className="TextPoint">
            <img src="./Images/VacansPoint.webp" alt=""></img>
            </div>
        </div>
    </div>
    </div>
    </div>
    )
}

export default FirstVacansBlock