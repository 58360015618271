import React from "react";
import './css/FourthBlock.css'
import { Link } from "react-router-dom";
import arrowBack from "./css/images/ArrowBack.svg"

import "./css/Vacans.css"

import IterVacMob from "./IterVacMob";
import IterVac from "./IterVac";

const VacansList =({selectedOption, setSelectedOption, setOptionNum, setPlace})=>{



    return(

        <div className="Techs"> 
        <Link className="BackButton" to="/"><img src={arrowBack} alt=""/><p>Назад</p></Link>
            {
                (window.innerWidth <=768) ? <IterVacMob setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/> : <IterVac setPlace={setPlace} setOptionNum={setOptionNum} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
            }
        </div>
    )
}

export default VacansList